@import url('@zalora/zui/dist/style.css');

/* base layer has been handled by @zalora/zui already */

/* @tailwind base; */
@tailwind components;
@tailwind utilities;

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

a,
button {
  -webkit-tap-highlight-color: transparent;
}

@layer utilities {
  .bg-cashback-gradient {
    @apply bg-gradient-to-b from-white to-teal-10;
  }

  .no-scrollbar {
    /* IE and Edge */
    -ms-overflow-style: none;

    /* Firefox */
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  input.hide-spin[type='number']::-webkit-inner-spin-button,
  input.hide-spin[type='number']::-webkit-outer-spin-button {
    @apply appearance-none;
  }

  input.hide-spin[type='number'] {
    appearance: textfield;
  }

  /* only work for 1 line */
  .capitalize-all {
    text-transform: lowercase;
  }

  .capitalize-all::first-letter,
  .capitalize-all::first-line {
    text-transform: capitalize;
  }

  .subcat:has(+ .has-subheading),
  .has-subheading:has(+ .has-subheading) {
    padding-bottom: 8px;
  }

  .product-item-recommendation {
    content-visibility: auto;
    contain-intrinsic-height: 400px;
  }
}
