/* stylelint-disable */
/* ====== Font Sizes ====== */
/*
 * Sizing with rem (root em)
 * http://snook.ca/archives/html_and_css/font-size-with-rem
 */

.fsxs {
  font-size: 1rem;
}

.fss {
  font-size: 1.1rem;
}

.fsm {
  font-size: 1.2rem;
}

/* text-align */

.txtRight {
  text-align: right;
}

.txtLeft {
  text-align: left;
}

.txtCenter {
  text-align: center;
}

/* font-weight */

strong,
.bold {
  font-weight: bold;
}

.normal {
  font-weight: normal;
}
