/* ==========================================================================
   Spacing-specific utilities
   ========================================================================== */

/**
 * Used to adjust the default spacing between components
 * Use with great care!
 *
 * u-<type><direction><size>
 *
 * A = all
 * T = top
 * R = right
 * B = bottom
 * L = left
 * H = horizontal
 * V = vertical
 *
 * n = none
 * s = small
 * m = medium
 * l = large
 * xl = extra large
 */

/* spacing helpers
p,m = padding,margin
a,t,r,b,l,h,v = all,top,right,bottom,left,horizontal,vertical
s,m,l,n = small(5px),medium(10px),large(20px),none(0px)
*/

.pts,
.pvs,
.pas {
  padding-top: 5px;
}

.ptm,
.pvm,
.pam,
.ptml,
.pvml,
.paml {
  padding-top: 12px;
}

.ptl,
.pvl,
.pal {
  padding-top: 20px;
}

.ptxl,
.pvxl,
.paxl {
  padding-top: 40px;
}

.prs,
.phs,
.pas {
  padding-right: 5px;
}

.prm,
.phm,
.pam,
.prml,
.phml,
.paml {
  padding-right: 12px;
}

.prl,
.phl,
.pal {
  padding-right: 20px;
}

.prxl,
.phxl,
.paxl {
  padding-right: 40px;
}

.pbs,
.pvs,
.pas {
  padding-bottom: 5px;
}

.pbm,
.pvm,
.pam,
.pbml,
.pvml,
.paml {
  padding-bottom: 12px;
}

.pbl,
.pvl,
.pal {
  padding-bottom: 20px;
}

.pbxl,
.pvxl,
.paxl {
  padding-bottom: 40px;
}

.pls,
.phs,
.pas {
  padding-left: 5px;
}

.plm,
.phm,
.pam,
.plml,
.phml,
.paml {
  padding-left: 12px;
}

.pll,
.phl,
.pal {
  padding-left: 20px;
}

.plxl,
.phxl,
.paxl {
  padding-left: 40px;
}

.mtn,
.mvn,
.man {
  margin-top: 0;
}

.mbn,
.mvn,
.man {
  margin-bottom: 0;
}

.mts,
.mvs,
.mas {
  margin-top: 5px;
}

.mtm,
.mvm,
.mam,
.mtml,
.mvml,
.maml {
  margin-top: 12px;
}

.mtl,
.mvl,
.mal {
  margin-top: 20px;
}

.mtxl,
.mvxl,
.maxl {
  margin-top: 40px;
}

.mtxxl,
.mvxxl,
.maxxl {
  margin-top: 90px;
}

.mrs,
.mhs,
.mas {
  margin-right: 5px;
}

.mrm,
.mhm,
.mam,
.mrml,
.mhml,
.maml {
  margin-right: 12px;
}

.mrl,
.mhl,
.mal {
  margin-right: 20px;
}

.mrxl,
.mhxl,
.maxl {
  margin-right: 40px;
}

.mrxxl,
.mhxxl,
.maxxl {
  margin-right: 40px;
}

.mbs,
.mvs,
.mas {
  margin-bottom: 5px;
}

.mbm,
.mvm,
.mam,
.mbml,
.mvml,
.maml {
  margin-bottom: 12px;
}

.mbl,
.mvl,
.mal {
  margin-bottom: 20px;
}

.mbxxl,
.mvxxl,
.maxxl {
  margin-bottom: 90px;
}

.mbxl,
.mvxl,
.maxl {
  margin-bottom: 40px;
}

.mls,
.mhs,
.mas {
  margin-left: 5px;
}

.mlm,
.mhm,
.mam,
.mlml,
.mhml,
.maml {
  margin-left: 12px;
}

.mll,
.mhl,
.mal {
  margin-left: 20px;
}

.mlxl,
.mhxl,
.maxl {
  margin-left: 40px;
}

.mlxxl,
.mhxxl,
.maxxl {
  margin-left: 90px;
}
