/**
Below styles are workarounds for backwards compatibility with old & new header
Will remove when rollout new header
*/

.headerv2-cms {
  margin-top: -20px;
}

.headerv2-cms .cms-shopbybrands {
  margin-left: 0;
}

.headerv2-cms .cms-wrapper,
.headerv2-cms .cms-range {
  margin-left: 0;
}

.headerv2-cms .cms-wrapperclothing {
  margin-left: 0;
}

.headerv2-cms .b-header__clickable {
  display: flex;
  align-items: center;
}

.headerv2-cms .cms-wrapper,
.headerv2-cms .cms-wrapperclothing {
  width: unset;
}

.headerv2-cms .b-header__category-dropdown-item {
  font-size: 14px;
}
