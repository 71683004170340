/* stylelint-disable */
/* Import color variables */
/** Header **/

.b-header__category-bar {
  background: #dfdfdf;
}

.b-header__localization-bar {
  background: #6d6e6e;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.b-header__text-selected {
  color: #00c8b4;
}

.b-header__localization-text {
  text-decoration: none;
  font-size: 12px;
  color: #f4f4f4;
  float: left;
}

.b-header__category-bar-text {
  text-decoration: none;
  font-size: 12px;
  float: left;
  text-transform: uppercase;
  color: #000;
}

.b-header__localization-selection {
  cursor: pointer;
  text-decoration: none;
}

.b-header__category-bar-sale {
  color: #ba4242;
}

.b-header-bar {
  margin: auto;
  display: table;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  max-width: 1280px;
  box-sizing: border-box;
}

.b-header-bar.Brands {
  padding-top: 1.15rem;
}

.b-header-bar.Brands > .size1of3:nth-child(2) {
  width: 100%;
}

.b-header__category-bar-inner {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}

.b-header__category-bar-sub-inner {
  flex-grow: 1;
}

.b-brand__featureBrands {
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.b-header-bar-catalogue {
  padding-top: 20px;
  border-collapse: separate;
}

.b-header__category-new {
  color: #048508;
}

.b-header__category-dropdown-title {
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
}

.b-header__category-dropdown-item {
  font-size: 11px;
}

.b-header__clickable:hover {
  cursor: pointer;
}

.b-header__nonclickable {
  cursor: default !important;
}

.b-header__category-dropdown-view-all {
  font-size: 11px;
  display: none;
}

.b-header__category-dropdown-box {
  display: flex;
}

.b-header__category-bar.l-pageWrapper {
  height: 100%;
  position: static;
}

.b-header__category-bar-text-sliding {
  display: inline-block;
  border-bottom: 4px solid transparent;
  padding-bottom: 12px - 4px;
  transition: 0.2s ease;
  white-space: nowrap;
  cursor: pointer;
}

.b-header__category-bar-text-sliding:hover {
  border-bottom: 4px solid #000;
  text-decoration: none;
}

.b-header-shadow {
  box-shadow: -1px 3px 7px #c9c9c9;
}

.js-navFix {
  position: fixed;
  top: 0;
  z-index: 110;
  width: 100%;
  transition: opacity 0.5s ease-in-out;
  -moz-transition: opacity 0.5s ease-in-out;
  -webkit-transition: opacity 0.5s ease-in-out;
}

.b-header-check-icon-zoom {
  zoom: 0.6;
}

.b-header-no-underline:hover {
  text-decoration: none;
}

.b-header__hr {
  width: 80%;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: auto;
  border-top: 1px solid #dfdfdf;
}

.b-header__category-new-catalog {
  color: #124707;
}

/* Floats */

.lfloat {
  float: left;
}

.rfloat {
  float: right;
}

.l-clear {
  clear: both;
}

/* display styles */

.headerv3 .block {
  display: block !important;
}

.headerv3 .inlineBlock {
  display: inline-block !important;
}

.headerv3 .inline {
  display: inline !important;
}

/* table based layouts */
/* .row, */
/* .cell { */
/*     float: none !important; */
/* } */
/* .row { */
/*     display: table; */
/*     width: 100%; */
/* } */
/* .cell { */
/*     display: table-cell; */
/* } */
/* .row .vTop { */
/*     vertical-align:top; */
/* } */
/* .row .vMid { */
/*     vertical-align:middle; */
/* } */
/* .row .vBot { */
/*     vertical-align:bottom; */
/* } */

/* List styles */

.simpleList {
  list-style: disc;
  padding-left: 13px;
}

.inlineList li {
  display: inline-block;
  margin-bottom: 4px;
}

/* Paragraphs (can be used on any block level element) */

.uip {
  margin: 1em 0;
}

.dec {
  color: #a2a2a2;
}

.circle {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #7e7e7e;
  line-height: 22px;
}

.with-gutters {
  padding: 0 16px;
}

.btn {
  transition:
    background-color 0.3s,
    color 0.2s;
  color: #fff;
  background-color: #000;
  border: 1px solid #000;
  box-sizing: border-box;
  text-transform: uppercase;
  display: inline-block;
  padding: 12px;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  letter-spacing: 1px;
}

.dropdown-menu-header a:focus,
.dropdown-menu-header a:hover {
  color: #000;
  text-decoration: underline;
}

.cms-list {
  margin-left: 10px;
  font-size: 11px;
}

.unit {
  float: left;
}

.size1of3 {
  width: 33.33333%;
}
